export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  GARAGE_SIZE: '<span class="garage-size" key="garage-size">({{size}})</span>',

  SEARCH_BOX_CLASS: 'form',
  SEARCH_BOX_FORM_CLASS: 'form-fieldset',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'form-button',
};
